import { GSD_THEME } from './content.constants'
import {
  CTA_TEXT_COMPARE_LOANS,
  CTA_TEXT_GET_STARTED,
  CTA_TEXT_COMPARE_CARDS,
  CTA_TEXT_COMPARE_INSURANCE,
  CTA_TEXT_FIND_MY_DEAL
} from './strings.constants'

export const MEGAMENUS = [
  {
    title: 'Health Insurance',
    linkLabel: 'Health',
    firstColumn: {
      title: 'Who is this cover for',
      links: [
        { label: 'Singles', url: '/health-insurance/singles/' },
        { label: 'Couples', url: '/health-insurance/couples/' },
        { label: 'Families', url: '/health-insurance/families/' },
        { label: 'Seniors', url: '/health-insurance/seniors/' },
        { label: 'Newly Married', url: '/health-insurance/newly-married-couples/' },
        { label: 'Single Parents', url: '/health-insurance/single-parents/' }
      ]
    },
    secondColumn: {
      title: 'Cover by type',
      links: [
        { label: 'Hospital Cover', url: '/health-insurance/hospital-cover/' },
        { label: 'Extras Cover', url: '/health-insurance/extras-cover/' },
        { label: 'Ambulance Cover', url: '/health-insurance/ambulance-cover/' },
        { label: 'Dental Cover', url: '/health-insurance/dental/' },
        { label: 'Optical Cover', url: '/health-insurance/optical/' },
        { label: 'Pregnancy', url: '/health-insurance/pregnancy/' }
      ]
    },
    thirdColumn: {
      title: 'Health Insurance Explained',
      links: [
        { label: 'Health Funds', url: '/health-insurance/companies/' },
        { label: 'Medicare Levy Surcharge', url: '/health-insurance/medicare-levy-surcharge/' },
        { label: 'Lifetime Health Cover Loading', url: '/health-insurance/lifetime-health-cover-loading/' },
        { label: 'Public Hospital Waiting Times', url: '/health-insurance/public-hospital-waiting-times/' },
        { label: 'Waiting Periods', url: '/health-insurance/waiting-periods/' },
        { label: 'View all guides', url: '/health-insurance/guides/', underline: true }
      ]
    },
    details: {
      text: '<b>Compare Club</b> makes health insurance simple - it only takes a minute to get an estimate that fits your needs.',
      ctaText: 'GET A QUOTE',
      formUrl: '/health-insurance/quote/form/step1/',
      productPage: '/health-insurance/',
      icon: '/icons/health-icon.svg'
    }
  },
  {
    title: 'Life Insurance',
    linkLabel: 'Life',
    firstColumn: {
      title: 'Who is this cover for',
      links: [
        { label: 'Over 50', url: '/life-insurance/over-50s/' },
        { label: 'Over 65', url: '/life-insurance/over-65/' },
        { label: 'Singles', url: '/life-insurance/singles/' },
        { label: 'Couples', url: '/life-insurance/couples/' },
        { label: 'Single Parents', url: '/life-insurance/single-parents-stay-home-parents/' }
      ]
    },
    secondColumn: {
      title: 'Cover by type',
      links: [
        { label: 'Life Cover', url: '/life-insurance/what-is-life-insurance/' },
        { label: 'Total & Permanent Disability (TPD)', url: '/life-insurance/tpd-insurance/' },
        { label: 'Trauma Cover', url: '/life-insurance/trauma-insurance/' },
        { label: 'Income Protection', url: '/life-insurance/income-protection-insurance/' },
        { label: 'Cover in Your Super', url: '/life-insurance/super/' },
        { label: 'Funeral Cover', url: '/life-insurance/funeral-insurance/' }
      ]
    },
    thirdColumn: {
      title: 'Life Insurance Explained',
      links: [
        { label: 'Life Insurers', url: '/life-insurance/companies/' },
        { label: 'Pre-Existing Conditions', url: '/life-insurance/pre-existing-conditions/' },
        { label: 'Smokers', url: '/life-insurance/smokers/' },
        { label: 'Bundling Policies', url: '/life-insurance/multiple-policies/' },
        { label: 'Extreme Sports', url: '/life-insurance/extreme-sports/' },
        { label: 'View all guides', url: '/life-insurance/guides/', underline: true }
      ]
    },
    details: {
      text: '<b>Compare Club</b> makes finding life insurance simple - it only takes a few minutes to see if there\'s a great value policy out there for you.',
      ctaText: 'GET A QUOTE',
      formUrl: '/life-insurance/quote/form/step1/',
      productPage: '/life-insurance/',
      icon: '/icons/life-icon.svg'
    }
  },
  {
    title: 'Energy',
    linkLabel: 'Energy',
    firstColumn: {
      title: 'Where are you located?',
      links: [
        { label: 'New South Wales', url: '/energy/compare-energy-nsw/' },
        { label: 'Victoria', url: '/energy/compare-energy-vic/' },
        { label: 'Queensland', url: '/energy/compare-energy-qld/' },
        { label: 'South Australia', url: '/energy/compare-energy-sa/' },
        { label: 'Australia Capital Territory', url: '/energy/compare-energy-act/' },
        { label: 'Tasmania', url: '/energy/compare-energy-tas/' }
      ]
    },
    secondColumn: {
      title: 'Energy by type',
      links: [
        { label: 'Choosing Gas or Electric?', url: '/energy/gas-or-electric/' },
        { label: 'Electricity Prices', url: '/energy/gas-or-electric/' },
        { label: 'Gas Prices', url: '/energy/electricity-pricing-structure/' },
        { label: 'Solar Power', url: '/energy/solar-save-energy-money/' }
      ]
    },
    thirdColumn: {
      title: 'Energy Explained',
      links: [
        { label: 'Companies', url: '/energy/companies/' },
        { label: 'Best Energy Providers', url: '/energy/top-10-companies/' },
        { label: 'Moving House', url: '/energy/moving-house/' },
        { label: 'Heat Pumps', url: '/energy/power-goes-out/' },
        { label: 'Switching Providers FAQs', url: '/energy/faqs-switching-providers/' },
        { label: 'View all guides', url: '/energy/guides/', underline: true }
      ]
    },
    details: {
      text: '<b>Compare Club</b> makes it simple to choose a better energy plan. It only takes a minute to see how much you could save.',
      ctaText: 'GET A QUOTE',
      formUrl: '/energy/quote/form/step1/',
      productPage: '/energy/',
      icon: '/icons/energy-icon.svg'
    }
  },
  {
    title: 'Home Loans',
    linkLabel: 'Home Loans',
    firstColumn: {
      title: 'By type',
      links: [
        { label: 'Refinancing', url: '/home-loans/refinancing/' },
        { label: 'First Home Buyer', url: '/home-loans/everything-to-know-buying-first-home/' },
        { label: 'Construction Loans', url: '/home-loans/construction-loans/' },
        { label: 'Retiree Refinancing', url: '/home-loans/refinancing-retirees/' },
        { label: 'Renovations', url: '/home-loans/finance-home-addition/' }
      ]
    },
    secondColumn: {
      title: 'Home Loans Explained',
      links: [
        { label: 'Lenders', url: '/home-loans/companies/' },
        { label: 'How to Compare Loans', url: '/home-loans/comparing-home-loans/' },
        { label: 'Interest Rates', url: '/home-loans/rba-rate-update/' },
        { label: 'Buying a Duplex', url: '/home-loans/buying-duplex-first-home/' },
        { label: 'Buying a Fixer Upper', url: '/home-loans/fixer-upper/' },
        { label: 'View all guides', url: '/home-loans/guides/', underline: true }
      ]
    },
    details: {
      text: '<b>Compare Club</b>  makes finding a home loan simple. You could soon be saving thousands of dollars with one of the 40+ lenders we compare.',
      ctaText: 'GET MY RATE',
      formUrl: '/home-loans/quote/form/step1/',
      productPage: '/home-loans/',
      icon: '/icons/homeloan-icon.svg'
    }
  }
]

export const GUIDES_INDEX_LINKS = [
  {
    label: 'Health',
    url: '/health-insurance/guides/',
    name: VERTICAL.HEALTH_INSURANCE
  },
  {
    label: 'Life',
    url: '/life-insurance/guides/',
    name: VERTICAL.LIFE_INSURANCE
  },
  {
    label: 'Energy',
    url: '/energy/guides/',
    name: VERTICAL.ENERGY
  },
  {
    label: 'Home Loans',
    url: '/home-loans/guides/',
    name: VERTICAL.HOME_LOANS
  },
  {
    label: 'Personal Finance',
    url: '/credit-cards/guides/',
    name: VERTICAL.CREDIT_CARDS
  }
]

export const COMPONENT_THEMES = {
  themes: ['primary', 'secondary', 'tertiary', 'light', 'destructive'],
  sizes: ['standard', 'small', 'medium', 'large'],
  menuItemThemes: ['primary', 'hover-with-border']
}

export const GUIDES_CONFIG = {
  defaultImage: 'https://asset.compareclub.com.au/content/default-featured-image.png',
  defaultAuthor: 'Compare Club',
  showQuickQuote: true,
  config: {
    [VERTICAL.HEALTH_INSURANCE]: {
      hasQuickQuote: true,
      guideHasGsdModule: true,
      articleEnableCardCta: false,
      articleEnableInlineCardCta: false,
      articleEnablePartnerSlider: true,
      GsdModuleTheme: GSD_THEME.VERTICAL,
      hasGsdDisclaimer: true,
      hasCallCta: true
    },
    [VERTICAL.LIFE_INSURANCE]: {
      hasQuickQuote: true,
      guideHasGsdModule: true,
      articleEnableCardCta: true,
      articleEnableInlineCardCta: false,
      articleEnablePartnerSlider: true,
      GsdModuleTheme: GSD_THEME.VERTICAL,
      hasGsdDisclaimer: true,
      hasCallCta: true
    },
    [VERTICAL.ENERGY]: {
      hasQuickQuote: true,
      guideHasGsdModule: false,
      articleEnableCardCta: false,
      articleEnableInlineCardCta: true,
      articleEnablePartnerSlider: false,
      acticleCtaText: CTA_TEXT_FIND_MY_DEAL,
      GsdModuleTheme: GSD_THEME.HORIZONTAL,
      hasGsdDisclaimer: true,
      hasCallCta: false
    },
    [VERTICAL.HOME_LOANS]: {
      hasQuickQuote: false,
      guideHasGsdModule: false,
      articleEnableCardCta: false,
      articleEnableInlineCardCta: false,
      articleEnablePartnerSlider: false,
      acticleCtaText: CTA_TEXT_GET_STARTED,
      GsdModuleTheme: GSD_THEME.HORIZONTAL,
      hasGsdDisclaimer: false,
      hasCallCta: true
    },
    [VERTICAL.CREDIT_CARDS]: {
      hasQuickQuote: false,
      guideHasGsdModule: false,
      articleEnableCardCta: false,
      articleEnableInlineCardCta: false,
      articleEnablePartnerSlider: false,
      acticleCtaText: CTA_TEXT_COMPARE_CARDS,
      GsdModuleTheme: GSD_THEME.HORIZONTAL,
      hasGsdDisclaimer: false,
      hasCallCta: true
    },
    [VERTICAL.PERSONAL_LOANS]: {
      hasQuickQuote: false,
      guideHasGsdModule: false,
      articleEnableCardCta: false,
      articleEnableInlineCardCta: false,
      articleEnablePartnerSlider: false,
      acticleCtaText: CTA_TEXT_COMPARE_LOANS,
      GsdModuleTheme: GSD_THEME.HORIZONTAL,
      hasGsdDisclaimer: false,
      hasCallCta: true
    },
    [VERTICAL.CAR_LOANS]: {
      hasQuickQuote: false,
      guideHasGsdModule: false,
      articleEnableCardCta: false,
      articleEnableInlineCardCta: false,
      articleEnablePartnerSlider: false,
      acticleCtaText: CTA_TEXT_COMPARE_LOANS,
      GsdModuleTheme: GSD_THEME.HORIZONTAL,
      hasGsdDisclaimer: false,
      hasCallCta: true
    },
    [VERTICAL.CAR_INSURANCE]: {
      hasQuickQuote: false,
      guideHasGsdModule: false,
      articleEnableCardCta: false,
      articleEnableInlineCardCta: false,
      articleEnablePartnerSlider: false,
      acticleCtaText: CTA_TEXT_COMPARE_INSURANCE,
      GsdModuleTheme: GSD_THEME.HORIZONTAL,
      hasGsdDisclaimer: false,
      hasCallCta: true
    }
  }
}

export const APP_ASSET_BASEURL = 'https://asset.compareclub.com.au/'
export const CC_DOMAIN = 'https://compareclub.com.au'

export const DOMAIN_PREFIXS = {
  develop: 'dev',
  uat: 'uat',
  staging: 'staging',
  preview: 'preview',
  local: 'localhost'
}

export const OFFICES = [
  {
    location: 'Sydney',
    phoneNumber: VERTICALS.Default.globalPhoneNumber,
    email: 'info@compareclub.com.au',
    address: 'Level 7, 222 Pitt Street, Sydney NSW, 2000'
  },
  {
    location: 'Melbourne',
    phoneNumber: VERTICALS.Default.globalPhoneNumber,
    email: 'info@compareclub.com.au',
    address: 'Level 6, 600 St Kilda Road, Melbourne VIC 3004'
  },
  {
    location: 'Brisbane',
    phoneNumber: VERTICALS.Default.globalPhoneNumber,
    email: 'info@compareclub.com.au',
    address: 'Level 3, 324 Queen Street, Brisbane City, QLD 4000'
  }
]
